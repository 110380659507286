import sanitizeHtml from 'sanitize-html'

import { Question } from './interfaces'

const indexConfig = {
    allowedTags: [],
    allowedAttributes: {},
    exclusiveFilter: (frame: any) => {
        return frame.tag === 'p' && !frame.text.trim()
    },
}
const sanitizeConfig = {
    allowedTags: [
        'p',
        'i',
        'em',
        'b',
        'strong',
        'sup',
        'sub',
        'ul',
        'ol',
        'li',
        'img',
        'u',
        'br',
        'span',
    ],
    allowedAttributes: {
        span: ['style'],
        img: ['src'],
    },
    allowedSchemesByTag: {
        img: ['data'],
    },
}

export const indexClean = (question: Question): Question => {
    delete question.created
    delete question.modified

    question.query = stripAll(sanitizeHtml(question.query, indexConfig))

    if (question.feedback) {
        question.feedback = stripAll(
            sanitizeHtml(question.feedback, indexConfig)
        )
    }
    if (Array.isArray(question.answers)) {
        for (const answer of question.answers) {
            if (answer.text) {
                answer.text = stripAll(sanitizeHtml(answer.text, indexConfig))
            }
            if (answer.feedback) {
                answer.feedback = stripAll(
                    sanitizeHtml(answer.feedback, indexConfig)
                )
            }
            if (answer.correct) {
                delete answer.correct
            }
        }
    }
    return question
}

export const sanitize = (question: Question): Question => {
    console.log(question.query)
    question.query = sanitizeHtml(question.query, sanitizeConfig)
    if (question.feedback) {
        question.feedback = sanitizeHtml(question.feedback, sanitizeConfig)
    }
    if (Array.isArray(question.answers)) {
        for (const answer of question.answers) {
            if (answer.text) {
                answer.text = sanitizeHtml(answer.text, sanitizeConfig)
            }
            if (answer.feedback) {
                answer.feedback = sanitizeHtml(answer.feedback, sanitizeConfig)
            }
        }
    }
    console.log(question.query)
    return question
}

export const stripAll = (toClean: string): string => {
    return toClean
        .replace(/(<([^>]+)>)/gi, '')
        .replace(/&lt;/g, '')
        .replace(/&gt;/g, '')
        .replace(/&quot;/g, '')
        .replace(/&amp;/g, '')
        .replace(/&nbsp;/g, ' ')
        .replace(/“/g, '')
        .replace(/ /g, ' ')
        .replace(/”/g, '')
        .replace(/</g, '')
        .replace(/>/g, '')
        .replace(/"/g, '')
        .replace(/:/g, '')
        .replace(/\./g, '')
        .replace(/,/g, '')
    // .replace(/&/g, '');
}
