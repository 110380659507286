








































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Tinymce from './Tinymce/index.vue';
import { uniqueId, isArray } from 'lodash';
import { Message } from 'element-ui';

import { UserModule } from '@/store/modules/user';
import { db, fire } from '@/firebase';
import { Question } from '../../functions/src/shared';
import { sanitize } from '../../functions/src/shared/clean';
import { scrollToTop } from '@/utils/scroll';
import { collectionURLs } from '@/utils/config';
import { getNextNumber, getStats } from '@/firebase/models/search';

@Component({
  components: {
    Tinymce,
  },
})
export default class Form extends Vue {
  static showMessage(type: any, message: any) {
    Message({
      message,
      type,
      dangerouslyUseHTMLString: true,
    });
  }

  static booleanize(data: any) {
    return data.reduce((o: any, key: string) => ({ ...o, [key]: true }), {});
  }

  @Prop({ default: '' }) title!: string;
  @Prop({ default: '' }) collection!: string;
  @Prop({ default: false }) embedImage!: boolean;
  @Prop({ default: false }) answerFeedback!: boolean;
  @Prop({ default: false }) simple!: boolean;

  rules = {
    rank: [{ required: true, message: 'Please select a rank', trigger: 'change' }],
    series: [{ required: true, message: 'Please select at least one series', trigger: 'change' }],
  };

  options = {
    ranks: ['sergeant', 'lieutenant', 'captain'],
    series: {},
    procedures: {},
    tags: {},
    next: 0,
  };

  question: Question | any = {
    id: 0,
    rank: '',
    series: [],
    tags: [],
    answers: [],
  };

  popoverSave = false;
  popoverDelete = false;
  popoverAnswer: any = {};
  loading = false;

  popoverAnswerDelete(index: string) {
    this.popoverAnswer[index] = !this.popoverAnswer[index];
  }

  addAnswer() {
    this.question.answers.push({ text: '', correct: false, id: uniqueId('answer-') });
  }

  onAnswerDelete(index: number, id: string) {
    this.popoverAnswer[id] = false;
    this.question.answers.splice(index, 1);
  }

  onAnswerSelect(itemIndex: number) {
    for (const [index, answer] of typeof this.question.answers !== 'boolean' ? this.question.answers.entries() : '') {
      answer.correct = index === itemIndex;
    }
  }

  onSubmit(formName: string, override = false) {
    (this.$refs.form as any).validate((valid: boolean) => {
      if (valid) {
        if (override) {
          this.popoverSave = false;
          this.onSave();
        } else {
          if (typeof this.question.answers === 'boolean') {
            this.onSave();
          } else {
            for (const answer of this.question.answers) {
              if (answer.correct) {
                this.onSave();
                return true;
              }
            }
          }
          this.popoverSave = true;
        }
        return true;
      } else {
        scrollToTop();
        return false;
      }
    });
  }

  onSave() {
    const id = this.$route.params.id;
    this.loading = true;
    const newSeries = [];
    for (const series of this.question.series) {
      newSeries.push(series.toLowerCase());
    }
    this.question.series = newSeries;
    const newProcedures = [];
    for (const procedure of this.question.procedures) {
      newProcedures.push(procedure.toLowerCase());
    }
    this.question.procedures = newProcedures;
    const newTags = [];
    for (const tag of this.question.tags) {
      newTags.push(tag.toLowerCase());
    }
    this.question.tags = newTags;
    if (Array.isArray(this.question.answers)) {
      for (const answer of this.question.answers) {
        delete answer.id;
      }
    }
    this.question.modified = fire.firestore.Timestamp.fromDate(new Date());
    this.question = sanitize(this.question);
    if (id) {
      db.collection(this.collection)
        .doc(id)
        .set(this.question)
        .then(() => {
          this.loading = false;
          Form.showMessage('success', 'Successfully saved the question');
          scrollToTop();
          setTimeout(this.getOverview, 2000);
        })
        .catch((error) => {
          this.loading = false;
          Form.showMessage('error', error.message);
        });
    } else {
      this.question.created = fire.firestore.Timestamp.fromDate(new Date());
      this.question.collection = this.title;
      db.collection(this.collection)
        .add(this.question)
        .then((docRef: any) => {
          this.loading = false;
          Form.showMessage(
            'success',
            `Successfully saved the question. <a href="/${collectionURLs[this.collection]}/edit/${
              docRef.id
            }" style="text-decoration:underline">View the question here.</a>`,
          );
          setTimeout(this.getOverview, 2000);
          scrollToTop();
          this.question = {
            id: 0,
            rank: '',
            series: [],
            procedures: [],
            tags: [],
          };
        })
        .catch((error) => {
          this.loading = false;
          Form.showMessage('error', error.message);
        });
    }
  }

  onDelete() {
    this.loading = true;
    db.collection(this.collection)
      .doc(this.$route.params.id)
      .delete()
      .then(() => {
        this.loading = false;
        Form.showMessage('success', 'Message successfully deleted');
        this.$router.back();
      })
      .catch((error) => {
        this.loading = false;
        Form.showMessage('error', error.message);
      });
  }

  onCancel() {
    this.$router.back();
  }

  created() {
    if (this.$route.params.id) {
      this.loading = true;
      db.collection(this.collection)
        .doc(this.$route.params.id)
        .get()
        .then((questionRef) => {
          if (questionRef.exists) {
            const question = questionRef.data();
            if (question && question.answers) {
              if (isArray(question.answers)) {
                for (const answer of question.answers) {
                  answer.id = uniqueId('answer-');
                }
              }
            }
            this.question = question;
          }
          this.loading = false;
        })
        .catch((err) => console.error(err));
    } else {
      if (this.simple) {
        this.question.answers = false;
      } else {
        this.question.answers = [{ text: '' }, { text: '' }, { text: '' }, { text: '' }];
      }
    }
    this.getOverview();
  }

  async getOverview() {
    await getStats(this.collection).then((results) => {
      this.options.series = results.series;
      this.options.procedures = results.procedures;
      this.options.tags = results.tags;
    });
    if (!this.$route.params.id) {
      this.question.id = await getNextNumber(this.collection).then((results) => results.next);
    }
  }

  get ranks() {
    return this.options.ranks;
  }

  get series() {
    if (Object.keys(this.options.series).length > 0) {
      return Object.entries(this.options.series).map(([key]) => key);
    }
  }

  get procedures() {
    if (Object.keys(this.options.procedures).length > 0) {
      return Object.entries(this.options.procedures).map(([key]) => key);
    }
    return [];
  }

  get tags() {
    if (Object.keys(this.options.tags).length > 0) {
      return Object.entries(this.options.tags).map(([key]) => key);
    }
    return [];
  }

  bookmark() {
    if (this.bookmarked) {
      return UserModule.RemoveBookmark(this.$route.params.id);
    } else {
      return UserModule.AddBookmark({
        collection: this.title,
        id: this.$route.params.id,
        question: this.question.query,
      });
    }
  }

  get bookmarked() {
    return !!UserModule.bookmarks[this.$route.params.id];
  }
}
